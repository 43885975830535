*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .product-table th, .product-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .product-table th {
    background-color: #f4f4f4;
  }
  
  .product-image {
    width: 50px;
    height: auto;
  }
  
  .status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  
  .status.pending,.status.paid {
    background-color: #6a5acd;
  }
  
  .view-btn {
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .view-btn:hover {
    background-color: #0056b3;
  }

  .order-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 21px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  
  .order-header h3 {
    font-size: 1.5rem;
    color: #555;
  }
  
  .order-header img {
    max-width: 100px;
    border-radius: 5px;
  }
  
  .order-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .order-section {
    flex: 1;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .order-section h4 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .order-section p {
    font-size: 1rem;
    margin: 5px 0;
  }
  
  strong {
    color: #333;
  }

  
  
  @media (max-width: 768px) {
    .order-info {
      flex-direction: column;
    }
  
    .order-header img {
      max-width: 80px;
    }
  }
  