body {
  font-family: Arboria;
    margin: 0;
    padding: 0;
  }
  
  .container {

    padding: 20px;
  }
  
  .navbar {
    display: flex;
    justify-content: space-around;
    background-color: #111;
    padding: 10px 0;
  }
  
  .navbar a {
    color: orange;
    text-decoration: none;
    font-size: 16px;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    text-transform: lowercase;
  }
  
  .sort-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  
  .sort-section select {
    margin-left: 10px;
    padding: 14px 14px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    margin-right: 18px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0px 21px;
    margin-bottom: 44px;
  }
  
  .product-card {
    text-align: center;
  }
  
  .product-card img {
    width: 100%;
    border-radius: 10px;
  }
  
  .product-card h2 {
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
  }
  
  .product-card p {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0px;
  }

  .Header{
    margin-top: 7px;
  }
  
  /* Media Queries for larger screens */
  @media (min-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 700px) {
    .Header {
      margin-top: 52px;
    }
  }
  
  @media (min-width: 1024px) {
    .product-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }