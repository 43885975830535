*{
    margin: 0;
    padding: 0;
    font-family: "Arboria";
    box-sizing: border-box;
}
.login-wrapper{
    width: 100%;
    height: 100vh;
}
.login-container{
    background-color: #ebebeb;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.login-form{
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 26px;
    border-radius: 8px;
    margin-top: 15px;
}
.form{
    width: 100%;
}
.login-form h5{
    font-size: 26px;
}
.login-form label{
    font-weight: bold;
}
.login-phone{
    display: flex;
    flex-direction: column;
}
.login-form input{
    padding: 10px 10px;
    background-color: #f9f6f6;
    outline: none;
    margin: 5px 0px 29px 0px;
    border: 1px solid #b2afaf;
    border-radius: 5px;
}
.form-btn{
    width: 100%;
    padding: 5px 0px;
    background-color: #283854;
    color: white;
    border-radius: 5px;
}
.login-text{
    margin-top: 5px;
    text-align: center;
}
.login-signup{
    color: orange;
}
.login-form input::placeholder{
    opacity: 0.6;
    font-weight: bold;
}
.login-signup{
    font-weight: bold;
}
.Loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    z-index: 1000;;
}