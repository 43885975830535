*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('fonts/HelveticaNeueCyr-BoldItalic.ttf') format('truetype'),
       url('fonts/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
       url('fonts/HelveticaNeueCyr-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Pro';
  src: url('fonts/SFProDisplay-Regular.woff2') format('woff2'),
       url('fonts/SFProDisplay-Regular.woff') format('woff'),
       url('fonts/SFProDisplay-Regular.ttf') format('truetype');
}


body {
    margin: 0;
    padding: 0;
  }
  
  .container {
    padding: 20px;
  }
  
  .breadcrumb {
    font-size: 14px;
    color: #fff;
    background-color: #111;
    padding: 10px;
    margin-top: 7px;
    align-items: center;
  }
  
  .main-section {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 33px;
  }
  
  .main-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .main-title {
    font-size: 28px;
    text-transform: lowercase;
    margin: 20px 0 10px 0;
  }
  
  .main-description {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }

  .contact-text{
    font-family: 'SF Pro';
  }
  
  .contact-title{
    font-family: 'HelveticaNeue';
  }
  
  .join-btn {
    padding: 15px 30px;
    background-color: #252338;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
    color: #EED7A6;
    border-radius: 13px;
  }
  
  .query-section {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    padding: 0px 35px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .query-header {
    font-size: 24px;
    text-transform: lowercase;
  }
  
  .query-description {
    font-size: 16px;
    color: #333;
    margin: 5px 0 20px 0;
  }
  
  .query-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-field {
    width: 100%;
    padding: 6px;
    margin: 10px 0;
    border: 1px solid #FC8630;
    border-radius: 5px;
    font-size: 16px;
  }
  
  textarea.input-field {
    resize: none;
  }
  
  .submit-btn {
    padding: 15px 30px;
    background-color: #252338;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 26px;
    width: 100%;
    color: #EED7A6;
    border-radius: 13px;
  }
  .join{
    padding: 0px 35px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .input-field::placeholder {
    font-family: "Arboria";
    /* font-weight: 600; */
    font-size: 18px;
    padding-left: 15px;
    color: #252338;
  }
  @media (max-width: 700px) {
    .breadcrumb {
      margin-top: 52px;
    }
  }