/* .cart-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 48px;
    margin-bottom: 29px;
}

.cart-items {
    display: flex;
    flex-direction: column;
}

.cart-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
}

.cart-item-image {
    width: 100px;
    height: 100%;
    object-fit: cover;
    margin-right: 20px;
}

.cart-item-details {
    flex-grow: 1;
}

.cart-total {
    margin-top: 20px;
    font-weight: bold;
} */

*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}

body {
    margin: 0;
    background-color: #f5f5f5;
  }
  
  .cart-page {
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .cart-header h2 {
    margin: 0;
  }
  
  .close-btn {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .cart-items {
    margin: 20px 0;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #ddd; */
    padding: 10px 0;
  }
  
  .cart-item-image {
    width: 110px;
    border-radius: 8px;
    height: 169px;
  }
  
  .cart-item-details {
    flex-grow: 1;
  }
  
  .cart-item-details h4 {
    margin: 0;
    font-size: 16px;
  }
  
  /* .cart-item-quantity {
    display: flex;
    align-items: center;
  } */

  .cart-item-quantity button{
    border: none;
    background-color: transparent;
    outline: none;
  }
  
  .cart-item-quantity{
    justify-content: space-around;
    display: flex;
    justify-content: space-around;
    border: 1px solid orange;
    padding: 2px 10px;
    background: none;
    /* color: orange; */
    cursor: pointer;
    font-size: 16px;
    border-radius: 11px;
  }
  
  .cart-item-quantity span {
    margin: 0 10px;
    font-size: 16px;
  }
  
  .cart-item-price {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  }
  
  .cart-footer {
    text-align: center;
  }
  
  .total-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
    border-top: 1px solid #ddd;
  }
  
  .checkout-btn {
    background-color: #1c1c44;
  color: white;
  border: none;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 13px;
  }

  .cart-content {
    text-align: center;
    margin-top: 50px;
    font-family: "Arboria";
    line-height: 20px;
    padding-bottom: 94px;
    border-bottom: 1px solid black;
  }
  
  .cart-content h2 {
    font-size: 18px;
    color: #1f1f41;
    margin-bottom: 30px;
  }
  .product-image1 {
    width: 180px;
    height: 240px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .product-title {
    font-size: 16px;
    color: #1f1f41;
  }
  
  .start-shopping-button {
    background-color: #1f1f41;
    color: #f7e8c0;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 31px;
  }
  
  @media (max-width: 768px) {
    .cart-page {
      padding: 10px 26px;
    }
  
    .cart-item {
      /* flex-direction: column; */
      align-items: flex-start;
    }
  
    .cart-item-details {
      margin-left: 0;
    }
  
    .checkout-btn {
      font-size: 14px;
    }
  }
