*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
  
  .checkout-container {
    /* max-width: 700px; */
    margin: auto;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }

  .checkout-wrapper{
    display: flex;
    gap: 21px;
  }
  
  .section {
    margin-bottom: 20px;
    padding:0px 20px;
    margin-top: 7px;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  
  .form-row .form-group {
    flex-basis: 30%;
  }
  
  .order-summary {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .summary-item input {
    width: 75%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .summary-item button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    width: 19%;
  }
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 9px;
  }
  
  
  .pay-now-btn {
    display: block;
    width: 47%;
    margin-bottom: 25px;
    margin-left: 15px;
    padding: 15px;
    background-color: #007bff;
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pay-now-btn:hover {
    background-color: #0056b3;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Dark background */
    color: #fff; /* White text */
  }
  
  /* Order Summary */
  .Contact {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    
  }
  
  .summary-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .account-info {
    margin-top: 20px;
  }
  
  .email {
    font-size: 1em;
    color: #aaa;
  }
  
  .logout-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .logout-button:hover {
    background-color: #ff3333;
  }
  
  /* Login Form */
  .login-form {
    width: 350px;
    padding: 20px;
    background-color: #1c1c1c;
    border-radius: 10px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); */
  }
  
  h3 {
    font-size: 1.2em;
    color: #ff3d3d;
    margin-bottom: 15px;
  }
  
  .email-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #ff3d3d;
    background-color: #2c2c2c;
    color: white;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .error-message {
    color: #ff3d3d;
    font-size: 0.9em;
  }
  
  .checkbox-container {
    margin-bottom: 20px;
    color: #aaa;
  }
  
  .login-button {
    padding: 10px 15px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .login-button:hover {
    background-color: #ff3333;
  }

  .order-create-section{
    width: 67%;
    margin-top: 50px;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
    .form-row .form-group {
      flex-basis: 100%;
    }
    .order-summary {
      margin-top: 20px;
    }
  }

  @media (max-width: 700px) {
    
  .order-create-section{
    width: auto;
    margin-top: 0px;
  }
  .checkout-wrapper{
    display: block;
  }
  .pay-now-btn {
    width: 94%;
  }
  }