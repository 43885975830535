/* .product-details-container {
    padding: 40px;
    display: flex;
    justify-content: center;
}

.product-details-card {
    display: flex;
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    max-width: 800px;
}

.product-image img {
    width: 350px;
    border-radius: 10px;
}

.product-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.product-price {
    font-size: 20px;
    font-weight: bold;
    color: #e74c3c;
}

.product-status {
    margin-top: 10px;
    font-size: 16px;
    color: #2ecc71;
}

.product-description {
    margin-top: 20px;
    line-height: 1.5;
}

.back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #2980b9;
} */

body {
  font-family: 'Arboria';
  /* margin: 0; */
  padding: 0;
}

.container {
  padding: 20px;
}

.breadcrumb {
  font-size: 14px;
  color: #fff;
  background-color: #111;
  padding: 10px;
  margin-top: 7px;
  align-items: center;
}

.product-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.product-image {
  width: 100%;
}

.product-info {
  width: 100%;
  padding: 0px 32px;
  margin-top: 20px;
  border-bottom: 1px solid #c4aa7b;
  /* margin: 0; */
}

.product-name {
  font-size: 24px;
  text-transform: lowercase;
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: bold;
}

.product-price {
  font-size: 24px;
  font-weight: 500;
  margin: 10px 0;
}

.size-chart {
  padding: 5px 15px;
  border: 1px solid orange;
  background-color: transparent;
  color: black;
  cursor: pointer;
  border-radius: 11px
}

.size-options p {
  margin: 10px 0;
  font-size: 16px;
}

.size-btn {
  padding: 4px 11px;
  border: 1px solid orange;
  background-color: transparent;
  color: orange;
  margin: 5px;
  cursor: pointer;
  border-radius: 9px;
}

.add-to-cart-btn,
.buy-now-btn {
  padding: 15px;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
}

.add-to-cart-btn {
  font-family: 'Arboria';
  background-color: #FC8630;
  border: none;
  color: white;
}

.buy-now-btn {
  font-family: 'Arboria';
  background-color: #252338;
  border: none;
  color: white;
}

.expandable-section {
  margin: 20px 0;
}

.section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  /* max-width: 412px; */
}

.section-content {
  margin-top: 10px;
  font-size: 14px;
  max-width: 412px;
}

.suggestions-section {
  margin-top: 40px;
  text-align: center;
  font-family: "Arboria"
}

.suggestion-title,
.suggestion-price {
  text-align: left;
  margin: 0px;
}

.suggestions-header {
  font-size: 24px;
  text-transform: lowercase;
  margin-bottom: 17px;
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: bold;
  font: bold;
  text-align: left;
  padding: 0px 31px;
}

.suggestions-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 0px 31px
}

.suggestion-card {
  text-align: center;
}

.suggestion-card img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;;
}

.discover-more-btn {
  margin-top: 20px;
  padding: 10px 30px;
  background-color: #252338;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 50px;
  width: 100%;
  max-width: 412px;
  border-radius: 12px;
  color: #ffcb85;
}

.quantity-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #272838;
  background-color: transparent;
  border-radius: 7px;
}

.quantity-selector button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 15px;
  font-size: 18px;
  cursor: pointer;
  color: #272838;
}

.product-section .slick-slider .slick-dots {
  bottom: 22px;
}

@media (max-width: 900px) {
  .suggestions-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 700px) {
  .breadcrumb {
    margin-top: 52px;
  }
  .suggestions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 700px) {

  /* .product-image {
        width: 50%;
    } */
  .product-info {
    width: 50%;
  }

  .product-section {
    flex-direction: row;
  }
}

@media (max-width: 470px) {
  .suggestions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}