*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
body {
    margin: 0;
  }
  
  .track-order-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; 
  }
  
  .breadcrumb {
    width: 100%;
    background-color: #1c1c44; 
    padding: 10px 20px;
    margin-top: 7px;
    align-items: center;
  }
  
  .breadcrumb span {
    color: #f3e2b7; 
    font-size: 1rem;
  }
  
  .order-status {
    text-align: center;
    margin-top: 50px;
  }
  
  .order-status h1 {
    font-size: 2rem;
    color: #1c1c44; 
    font-style: italic;
  }
  
  .order-status button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid orange;
    color: #1c1c44;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
  }
  
  .order-status button:hover {
    background-color: orange;
    color: white;
  }
  
  @media (min-width: 768px) {
    .breadcrumb span {
      font-size: 1.2rem;
    }
  
    .order-status h1 {
      font-size: 3rem;
    }
  
    .order-status button {
      font-size: 1.2rem;
      padding: 15px 30px;
    }
  }
  @media (max-width: 700px) {
    .breadcrumb {
      margin-top: 52px;
    }
  }