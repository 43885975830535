*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: -16px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 13px;
    border-radius: 10px;
    width: 346px;
    max-width: 78%;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .size-chart-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .size-chart-table th,
  .size-chart-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .size-chart-table th {
    background-color: #f2f2f2;
  }