
/* 
@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/HelveticaNeue.woff2") format("woff2"),
       url("fonts/HelveticaNeue.woff") format("woff"),
       url("fonts/HelveticaNeue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/HelveticaNeueCyr-BoldItalic.woff2") format("woff2"),
       url("fonts/HelveticaNeueCyr-BoldItalic.woff") format("woff"),
       url("fonts/HelveticaNeueCyr-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.container1 {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.contain-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 5px; */
}
.holiday-image-link{
    text-decoration: none;
}
.holiday-image-header{
    text-decoration: none;
    font-size: 18px;
    color: orange;
    font-size: 25px;
}

.contain-menu {
    display: none;
}

.menus {
    display: flex;
    align-items: center;
    gap: 33px;
    font-family: "'Helvetica Neue', sans-serif";
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: white;

}

.menu-items {
    display: block;
    text-decoration: none;
    position: relative;
}

.menu-items button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 17px;
    padding: 10px 0px;
}

.main-nav .dropdown {
    position: absolute;
    right: -82px;
    top: 35px;
    list-style: none;
    display: none;
    background-color: white;
    padding: 5px 0px 5px 0px;
    border-radius: 10px;

}

.dropdown.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.main-nav .dropdown .dropDownSubMenu {
    position: absolute;
    left: 100%;
    top: -4px;
    width: 213px;
    padding: 5px;
    box-sizing: content-box;
    border-left: 1px solid black;
}
.dropdown .menu-items button {
    width: 213px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px 8px 8px;
}

.dropdown .menu-items button:hover {
    background-color: rgb(112, 104, 104);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    border-left: 4px solid rgb(0, 0, 0);
}

.btn {
    width: 130px;
    height: 50px;
    border: 3px solid orange;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
}

.button {
    text-decoration: none;
    color: orange;
    border: none;
    font-size: 20px;
}

.main-menu {
    display: none;
}
a {
    text-decoration: none;
    color: inherit;
  }
  
  .container {
    width: 100%;
  }
/*   
  .contain-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
  } */
  
  .contain-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px;
  }
  
  /* .holiday-image img {
    width: 100px;
    display: flex;
    cursor: pointer;
    margin-left:10px
  } */
  
  .main-nav {
    display: flex;
    justify-content: flex-end;
  }

  .coupon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px; /* Adjust this based on your text size */
    overflow: hidden; /* Hide out-of-view content */
  }

  .coupon-text {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    transform-origin: center;
    opacity: 0; /* Start with hidden text */
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out; /* Smooth transitions */
  }
  
  .move-up {
    opacity: 0;
    transform: translateY(-100%); 
  }

  .move-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .menus {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 5px;
  }
  
  .menu-item {
    position: relative;
    width: auto;
    font-family: "Helvetica Neue" !important;
  }

  .mobile-menu-item {
    width: 100%;
  }

  .menu-item a {
    font-family: "Helvetica Neue";
    font-size: 17px;
    padding: 0px 15px;
    color: black;
    display: block;
    cursor: pointer;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }
  
  .mobile-menu-item a {
    padding: 4px 15px;
    font-size: 20px;
    color: black;
    display: block;
    cursor: pointer;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  /* Submenu hidden by default */
  .submenu {
    position: absolute;
    top: 22px;
    left: -20px;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    list-style: none;
    /* padding: 10px 0; */
    width: 150px;
    z-index: 100;
    padding-left: 10px;
    border-radius: 6px;
    text-transform: uppercase;
  }
  .mobile-submenu{
    position: static;
    box-shadow: none;
  }
  
  /* Submenu links styling */
  .submenu li a {
    padding: 5px 20px;
    display: block;
    white-space: nowrap;
    font-size: 14px;
    color: black;
    font-family:Arboria;
    font-weight:500;
    font-style: normal;
  }
  
  
  /* Show submenu on hover */
  .menu-item:hover .submenu {
    display: block;
  }
  .mobile-submenu:hover .submenu {
    display: block;
  }

  .submenu-link {
    text-decoration: none;
    color: black; /* Default color */
  }
  
  .mobile-submenu li a:hover {
    color: orange; /* Hover color */
  }
  .webView-icon{
    display: flex;
    margin-right: 23px;
  }

  li{
    list-style-type: none;
  }

  .social-media-icon{
    display: flex;
    gap: 20px;
    position:absolute;
    top:16px;
    left:9px
  }

  .social-media-icon1{
    display: none;
  }
  .moile_social-media-icon1{
    display: flex;
    gap: 20px;
    position:absolute;
    bottom: 73px;
    left: 21px;
    z-index:100000000000;
  }

  .search-bar {
    width: 97%;
    position: absolute;
    top: 14px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
  }
  
  .search-bar input {
    width: 97%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 25px;
    margin-right: 10px;
  }
  .search-bar select, .search-bar1 select, .search-bar2 select{
  width: 100%;
  border: none;
  outline: none;
}
.search-bar input, .search-bar1 input, .search-bar2 input{
  width: 100%;
  border: none;
  outline: none;
}

.result-list3{
  color: black;
  z-index: 10000;
  position: absolute;
  top: 68px;
  background-color: #f1c675;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  left: 0px;
  padding: 9px 18px;
}
.result-list1{
  color: black;
  z-index: 10000;
  position: absolute;
  top: 91px;
  background-color: #f1c675;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  left: 0px;
  padding: 9px 18px;
}

.result-list2{
  color: black;
  z-index: 10000;
  position: absolute;
  top: 115px;
  background-color: #f1c675;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  left: 0px;
  padding: 9px 18px;
}

  .search-bar1 {
    font-size: 19px;
    width: 97%;
    position: absolute;
    top: 34px;
    right: 9px;
    display: flex;
    align-items: center;
    padding: 9px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* border-radius: 25px; */
    z-index: 10000;
  }

  .search-bar2{
    width: 100%;
    margin-right: 0px;
    position: absolute;
    top: 65px;
    right: 0px;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }

  .search-bar1 input,.search-bar2 input {
    width: 97%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 25px;
    margin-right: 10px;
    background-color: white;
}

.icon-image{
  display: flex;
  cursor: pointer;
  width:100px;
  margin-left:10px
}

.coupon-container {
  position: relative;
  height: 50px; /* Adjust as needed */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  opacity: 0;
  transform: translateY(100%) rotate(0deg);
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


/* .coupon-container {
  position: relative;
  height: 50px; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
} */

/* .coupon.visible {
  opacity: 1;
  transform: translateY(0);
}

.coupon.hidden {
  opacity: 0;
  transform: translateY(-100%);
} */

.coupon.enter {
  animation-name: rotateIn;
}

.coupon.exit {
  animation-name: rotateOut;
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateOut {
  0% {
    opacity: 1;
    transform: translateY(0) ;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%) ;
  }
}

  
  
  /* Mobile styling */
  @media (max-width: 768px) {
    /* .menus {
      display: none;
    } */
  
    .mobile-main-nav {
      display: flex;
      flex-direction: column;
    }
  
    .mobile-menus {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-family: "'Helvetica Neue', sans-serif";
      font-weight:700;
      font-style:italic;
    }
  
    .mobile-menu-items {
      display:flex;
    }
  }
  

@media screen and (max-width:700px) {
  .container1 {
    display: flex;
  }

  .webView-icon{
    display: none;
  }
    .menus {
        display: none;
    }

    .contain-menu {
        display: block;
        font-size: 40px;
    }

    .mobile-main-nav {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: white;
        z-index: 9999;
        display: block;
    }
    .mobile-container1{
      display: flex;
    }
    .mobile-cross-icon{
        display: none;
    }
    .mobile-cross-icon1{
        display: block;
    }

    .mobile-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px;
        font-size: 21px;
    }

    .mobile-menu button {
        border: none;
        font-size: 29px;
    }

    .mobile-menu h4 {
        color: red;
        font-size: 33px;
    }

    .mobile-menus {
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 0px;
        padding: 0px 32px;
        margin-top: 59px;
    }

    .mobile-menu-items {
        width: 100%;
        display: block;
        text-decoration: none;
        position: relative;
    }
    .mobile-menu-items button{
        width: 100%;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        font-size: 21px;
        font-weight: 600px;
        position: relative;
        opacity: 0.8;
        border-bottom: 1px solid black;

    }
    .mobile-main-nav .mobile-dropdown{
        width: 100%;
        position: static;
        list-style-type:none;
        background-color: #e9e9e9;
        padding: 10px 18px;
    }
    .mobile-dropdown .menu-items button{
        width: 360px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid white;
    }
    .mobile-main-nav .mobile-dropdown .dropDownSubMenu {
        position: static;
        width: 360px;
        background-color: #e9e9e9;
        border-left: none;
        padding-left: 20px;
    }
    .mobile-dropdown .menu-items button:hover {
        background-color:orangered;
        color: white;
        cursor: pointer;
        border-left: 4px solid rgb(199, 122, 94);
    }
    .contain-nav{
      margin-top: 0px;
      padding: 0px 0px;
  }
  .social-media-icon{
    display: none;
  }
  .mobile-search-bar2{
    display: none;
  }
  .result-list2{
    display: none;
  }
  .holiday-image{
    display: none;
  }
  
}
