*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .data-table {
    border-collapse: collapse;
    width: 70%;
    border: 1px solid #ddd;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 12px;
    font-family: Arial, sans-serif;
  }
  
  .data-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .data-table tr:hover {
    background-color: #f1f1f1;
  }