*{
    margin: 0;
    padding: 0;
    font-family: "Arboria";
    box-sizing: border-box;
  }
body {
    margin: 0;
    background-color: #f5f5f5;
}

/* Home Container */
.home-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header Banner */
.header-banner {
    position: relative;
    width: 100%;
}

.header-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.overlay-text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
}

.overlay-text p {
    font-size: 2rem;
    margin: 0;
    color: #ffca84;
}

.overlay-text button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
}

.overlay-text button:hover {
    background-color: orange;
}

/* Find Us Section */
.find-us-section {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: #f0f0f0;
}

.find-us-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.find-us-images {
    display: flex;
    gap: 10px;
}

.image-placeholder {
    width: 240px;
    background-color:#ececec;
}

/* Store Policy */
.store-policy {
    width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    font-family: "Arboria";
    font-weight: 500;
    line-height: 24px;
}

.store-policy h3 {
    font-size: 1.4rem;
    margin-bottom: 11px;
}

.store-policy ul {
    list-style: none;
    padding: 0;
}

.store-policy ul li {
    font-size: 1rem;
    margin: 5px 0;
    color: #1c1c44;
    cursor: pointer;
}

/* Footer Styles */
.footer-section {
    background: #262626;
    padding-bottom: 0;
}

.newslatter-form {
    margin-bottom: 90px;
}

.newslatter-form form {
    position: relative;
}

.newslatter-form form input {
    width: 100%;
    border: 2px solid #454747;
    border-radius: 50px;
    height: 53px;
    background: transparent;
    color: #535353;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    padding-left: 30px;
    position: relative;
}

.newslatter-form form button {
    width: 310px;
    height: 53px;
    background: #B0BCC2;
    border: 2px solid #B0BCC2;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    position: absolute;
    right: 0;
}

.footer-widget {
    margin-bottom: 40px;
}

.footer-widget h4 {
    color: #fff;
    font-size: 26px;
    margin-bottom: 44px;
}

.footer-widget ul li {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    list-style: none;
    line-height: 36px;
    opacity: 0.5;
}

.footer-support{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.find-us-images {
    display: flex;
    gap: 16px; 
    overflow-x: auto;
    padding: 16px; 
    scroll-behavior: smooth; 
  }
  .find-us-images {
    width: 100%;
    max-width: 100%;
  }
  
  .find-us-images::-webkit-scrollbar {
    height: 8px; 
  }
  
  .find-us-images::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px; 
  }
  
  .find-us-images::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .image-placeholder {
    flex: 0 0 auto; 
    width: 200px; 
    height: 277px;
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    overflow: hidden;
  }
  .image-placeholder {
    flex: 0 0 auto;
  }
  .find-us-images {
    display: flex;
    white-space: nowrap;
    gap: 16px;
    overflow-x: auto;
    padding: 16px;
    scroll-behavior: smooth;
  }
  
  
  
  .header-image1 {
    width: 100%;
    height: 200px;
    object-fit: cover; 
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1); 
  }
  
  .header-image1:hover {
    transform: scale(1.2);
  }
  .swiper-initialized .swiper-button-next,.swiper-initialized .swiper-button-prev{
    display: none;
  }

  .swiper-pagination{
    display: none;
  }
  
/* Responsive Styles */
@media (min-width: 768px) {
    .find-us-images {
        gap: 20px;
    }

    .overlay-text p {
        font-size: 2.5rem;
    }

    .overlay-text button {
        font-size: 1.2rem;
    }

    .find-us-section h2,
    .store-policy h3 {
        font-size: 1.4rem;
    }
}

@media (max-width: 736px) {
    .footer-support{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

/* @media (max-width: 540px) {
    .image-placeholder {
        width: 56%;
    }
} */

