*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.shipping-policy-container,.term-policy-container,.term-policy-container1,.store-policy-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
  }
  
  .shipping-policy-container h4 {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
  }
  
  .shipping-policy-container p ,.term-policy-container,.term-policy-container1,.store-policy-container{
    line-height: 1.6;
    font-size: 1rem;
    color: #555;
  }
  .section-header h6{
    width: 91%;
  }
  
  .terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .term-policy-container {
    /* margin-bottom: 15px; */
    border-radius: 8px;
    overflow: hidden;
    /* border: 1px solid #e0e0e0; */
    transition: all 0.3s ease;
  }

  .term-policy-container1 {
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease;
  }
  
  .term-policy-container1:hover {
    border-color: #007bff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .section-header {
    /* padding: 15px 20px; */
    /* background-color: #007bff; */
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .section-header h6 {
    font-size: 1.1rem;
    margin: 0;
    flex-grow: 1;
  }
  
  .section-header span {
    font-size: 1.2rem;
  }
  
  /* .section-header:hover {
    background-color: #0056b3;
  } */
  
  /* .term-policy-container p {
    padding: 15px 20px;
    margin: 0;
    background-color: #fff;
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
    border-top: 1px solid #e0e0e0;
    animation: fadeIn 0.3s ease;
  } */
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    .section-header h6 {
      font-size: 1rem;
    }
  
    .term-policy-container p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .section-header h6 {
      font-size: 0.9rem;
    }
  
    .section-header span {
      font-size: 1rem;
    }
  
    .term-policy-container p {
      font-size: 0.85rem;
    }
  }
  
  
  @media (max-width: 768px) {
    .shipping-policy-container {
        padding: 29px;
    }
  
    .shipping-policy-container h4 {
      font-size: 1.3rem;
    }
  
    .shipping-policy-container p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .shipping-policy-container h4 {
      font-size: 1.2rem;
    }
  
    .shipping-policy-container p {
      font-size: 0.9rem;
    }
  }
  