*{
  margin: 0;
  padding: 0;
  font-family: "Arboria";
  box-sizing: border-box;
}
.otp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .otp-container {
    max-width: 500px;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
  }
  
  .otp-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .otp-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    margin: 0 5px;
    border: 2px solid #ccc;
    border-radius: 4px;
  }
  
  .otp-separator {
    margin: 0 8px;
    font-size: 18px;
    color: #333;
  }
  
  .otp-submit-btn {
    margin-top: 2rem;
    padding: 0.75rem 1.5rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .otp-submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .otp-submit-btn:hover:not(:disabled) {
    background-color: #45a049;
  }
  