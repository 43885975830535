*{
    margin: 0;
    padding: 0;
    font-family: Arboria;
    box-sizing: border-box;
  }
.modal-overlay {
    position: fixed;
    top: -17px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 652px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  .modal-actions{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 54px;
  }

  @media (max-width: 600px) {
    .modal-content {
        width: 83%;
        padding: 13px;
    }
}
  